<template>
  <section class="container-fluid position-relative pl-0 pr-0">
    <div class="locatii">
      <div class="container">
        <div class="row align-top">
          <div
            class="offset-xl-1 offset-lg-0 offset-md-0 offset-0 col-xl-4 col-lg-6 col-md-6 col-11"
          >
            <p>
              <img
                class="d-block d-md-none"
                src="@/assets/images/about/map-locatii.png"
                alt="Testare Semineu"
              />
            </p>
            <h3>PEFOC, in toata Romania.</h3>
            <p>
              Comercializam si montam o gama vasta de seminee pe lemne, focare
              si cosuri de fum profesionale. Oriunde te-ai afla, echipa PEFOC
              vine la tine. Fie pentru a-ti livra produsul achizitionat, fie
              pentru a-ti oferi serviciile noastre profesionale.
            </p>
          </div>
          <div
            class="col-xl-7 col-lg-6 col-md-6 col-12 d-none d-md-block right"
          >
            <figure class="figure">
              <img
                src="@/assets/images/about/map-locatii.png"
                alt="Testare Semineu"
              />
            </figure>
          </div>
        </div>
      </div>
      <!-- <div class="loc-img-over eight-section col-lg-10 col-md-11 col-sm-11 col-xs-12"></div> -->
    </div>
  </section>
</template>

<style scoped>

@media (max-width: 1200px) {
  .locatii {
    background-size: cover;
}
}

</style>