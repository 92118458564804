<template>
  <section class="block-text text-center pt-0">
    <div class="container">
      <div class="row">
        <div
          class="offset-xl-1 offset-lg-0 offset-md-0 offset-xs-0 col-xl-10 col-lg-12 col-md-12 col-12"
        >
          <h3 class="ml-0">Investim in noi si excelam pentru tine</h3>

          <p>
            Participarea frecventa la formari profesionale, specializari si
            conferinte organizate cu tehnicienii, mesterii si colaboratorii din
            toata tara este de o reala importanta pentru noi; astfel vom a
            ramane la zi cu toate noutatile aparute in domeniu si vom putea
            oferi la randul nostru servicii de calitate inalta clientilor. Este
            important sa investim mereu in personalul de lucru pentru ca in
            momentul in care vom intampina dificultati pe teren sa putem oferi
            solutia optima clientilor nostri, conform principiului ''ce semeni,
            aceea culegi''.
          </p>
        </div>
      </div>
    </div>
  </section>
  
</template>