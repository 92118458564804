<template>
  <section class="partenerii-nostri pb-0">
    <div class="container-md">
      <div class="row vertical-align">
        <div class="col-md-7 col-sm-12 col-12 bg-black block-text over-bg left">
          <h3>Parteneriate de incredere</h3>
          <p>
            Parteneriatele cu marile companii recunoscute in intreaga lume au ca
            scop oferirea garantiei pe o durata cat mai lunga pentru semineele
            realizate de noi.
          </p>

          <p>
            Atat prin ceea ce noi producem cat si prin produsele partenerilor
            producatori de incredere din Germania, Cehia si Polonia vrem sa
            oferim clientilor nostri sisteme complete de incalzire calitative
            care sa reprezinte o investitie serioasa pe viata.
          </p>
        </div>
        <div class="col-md-5 col-sm-12 col-12 pl-0 pr-0 right">
          <figure class="figure">
            <img
              src="@/assets/images/about/partenerii-nostri.jpg"
              alt="Parteneri"
            />
          </figure>
        </div>
      </div>
      <div class="row">
        <div
          class="offset-xl-7 offset-lg-7 offset-md-6 offset-4 col-xl-5 col-lg-5 col-md-6 col-8"
        >
          <p class="img-descrip text-right">
            Parteneriatul de lunga durata cu compania Kratki a ajuns la un nivel
            inalt, rezultand mii de clienti multumiti.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

@media (max-width: 576px) {
  .partenerii-nostri {
    padding-top: 0;
  }
}

</style>