<template>
  <section class="block-text text-center motivation">
    <div class="container">
      <div class="row">
        <div class="offset-xl-1 col-xl-10 col-lg-12 col-12">
          <h3>Suntem pasionati, perseverenti si determinati.</h3>

          <p>
            Suntem priceputi in domeniul semineelor si dorim sa fim buni in ceea
            ce facem. Acum ne mandrim cu cei peste 9000 de clienti multumiti in
            fiecare an, peste 2000 de seminee montate anual realizate de
            echipele proprii impreuna cu partenerii specializati Pefoc.ro si un
            stoc mare de peste 50.000 de articole prezentand o gama
            diversificata.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'Motivation',
}
</script>