<template>
  <section class="testare">
    <div class="container-md">
      <div class="row vertical-align">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 pl-0 pr-0">
          <h3 class="d-block d-md-none on-mobile">
            Oferim caldura, confort si siguranta.
          </h3>
          <figure class="figure">
            <img src="@/assets/images/about/testare-semineu.webp" alt="Testare Semineu" />
          </figure>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 d-none d-md-block">
          <h3>Oferim caldura, confort, si siguranta.</h3>
          <p>
            Alegand oricare din produsele noastre aveti certitudinea calitatii fiecarui
            produs pe care il comercializam.
          </p>
          <p>
            Pe langa aspectul deosebit, fiecare produs este verificat si testat cu
            strictete sa corespunda normelor de siguranta in vigoare.
          </p>
        </div>
      </div>
      <div class="row d-none d-md-block">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <p class="img-descrip text-left">
            Testarea unui semineu pentru functionarea la parametri tehnici ridicati
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6"></div>
      </div>
    </div>
  </section>
</template>

<style scoped>
@media (max-width: 768px) {
  .despre-noi .main-area .testare h3 {
    margin-left: 20px;
    text-align: left;
  }

  .testare img {
    max-width: 100%;
  }

  .testare figure {
    margin-bottom: 0;
  }
}

@media (max-width: 576px) {
  .testare {
    padding-bottom: 0;
  }
}
</style>
