<template>
  <section class="despre-noi-end position-relative">
    <div class="container">
      <div class="investim-content">
        <div class="row vertical-align">
          <div class="col-xl-6 col-lg-6 col-md-6 col-12 pl-0 pr-0 order2">
            <figure class="figure">
              <img
                src="@/assets/images/about/despre-noi.jpg"
                alt="Inovatie"
              />
            </figure>
          </div>
          <div
            class="col-xl-6 col-lg-6 col-md-6 col-12 bg-red block-text left pl-0"
          >
            <h3>Noi facem tot, <br />tu pui pe foc.</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-red half-section right d-block d-md-none"></div>
  </section>
</template>