<template>
  <section id="intro">
    <div class="container">
      <div class="row">
        <div class="offset-lg-1 col-lg-9 col-md-12 col-12">
          <h3 class="about-us-intro-title">Totul incepe in familie...</h3>

          <p class="about-us-intro-content">
            Povestea noastra incepe acum 22 ani, moment in care am intrat pe
            piata semineelor din Romania. In urma unei specializari in
            constructia semineelor din anul 1998 ce a avut loc in Germania,
            Ovidiu Prodea isi incepe activitatea sa practicand aceasta meserie
            in Romania. Ca urmare, in anul 1999 monteaza primul semineu ce
            functioneaza perfect si astazi. In momentul actual afacerea familiei
            Prodea a ajuns la a doua generatie, fiind dezvoltata in continuare
            de cei doi fii ai d-lui Ovidiu si creand o echipa pasionata si
            profesionista.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Intro",
};
</script>

<style scoped>

/* .about-us-intro-title {
  padding-bottom: 30px0;
} */

.about-us-intro-content {
  font-size: 14px;
}

@media (max-width: 768px) {
  .about-us-intro-title {
    padding: 0;
  }
}

@media (max-width: 576px) {
  .about-us-intro-title {
    padding: 30px 0;
  }
}


</style>