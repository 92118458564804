<template>
  <section class="inceputuri">
    <div class="container-md">
      <div class="row vertical-align">
        <div class="col-lg-7 col-md-6 col-12 bg-black left">
          <img
            class="quote-icon d-block d-md-none"
            src="@/assets/images/about/quote_icon.png"
            alt=""
          />

          <p class="quote">
            “Inca de la inceput, am crezut in calitatea produselor, a valorilor
            si ideilor, cat si a intregului proces de prestare a serviciilor.
            Iata-ne azi, dupa 20 de ani, mai puternici ca niciodata.“
          </p>
          <p class="nume"><span>OVIDIU PRODEA, FONDATOR PEFOC</span></p>
        </div>
        <div class="col-lg-5 col-md-6 col-12 pl-0 pr-0 right">
          <figure class="figure">
            <img
              src="@/assets/images/about/ovidiu_prodea_fondator.jpg"
              alt="Ovidiu Prodea"
            />
          </figure>
        </div>
      </div>
      <div class="row">
        <div
          class="offset-md-7 offset-2 col-md-5 col-sm-5 col-sm-10 col-10 pr-0"
        >
          <p class="img-descrip text-right">
            Ovidiu Prodea alaturi de unul din primele seminee montate de el, in
            1999, un semineu perfect functionabil si astazi.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'Beginnings',
}
</script>

<style scoped>

@media (max-width: 768px) {

	.despre-noi .inceputuri p.quote {
    font-size: 20px;
	}

}

@media (max-width: 576px) {

	.despre-noi .inceputuri p.quote {
    font-size: 16px;
	}

}
</style>