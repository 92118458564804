<template>
  <section class="testare">
    <div class="container-md">
      <div class="row vertical-align">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pl-0 pr-0">
          <h3 class="on-mobile">Oricand, la dispozitia clientilor nostri.</h3>
          <figure class="figure">
            <img
              src="@/assets/images/about/expozitie.jpg"
              alt="Testare Semineu"
            />
            <figcaption>
              <p
                class="img-descrip text-left no-mobile col-lg-11 col-md-10 col-sm-10 pl-0"
              >
                Pefoc participa frecvent la targuri si expozitii anuale in
                diferite orase din tara, fiind astfel in legatura directa cu
                oamenii si avand posibilitatea sa ofere pe loc solutii concrete
                si personalizate.
              </p>
            </figcaption>
          </figure>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 no-mobile">
          <h3 class="mt-0 pt-0">Oricand, la dispozitia clientilor nostri.</h3>
          <p>
            Suntem oricand la dispozitia clientilor nostri si oferim consultanta
            gratuita si ajutor in alegerea si comandarea produselor potrivite.
            Oferim suport permanent pentru produsele noastre si suntem aici
            pentru tine oricand ai nevoie. Toate solicitarile de servicii vor fi
            rezolvate in termen de cateva zile. Suportul pentru clienti este
            oferit direct din fabrica, de catre tehnicienii nostri calificati ce
            cunosc produsele in detaliu. Transportul produselor pana la
            domiciliu este garantat de noi, iar produsele voluminoase (focare,
            termofocare, sobe, cosuri de fum, seminee pe gaz) beneficiaza de
            transport gratuit. Asa cum spune si sloganul firmei '' Noi facem
            tot, tu pui pe foc '', oferim servicii complete de la
            comercializare, productie, consultanta si transport pana la punerea
            in opera si montajul efectiv la client.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'SecondTesting',
}
</script>

<style scoped>

@media (max-width: 768px) {
  .despre-noi .main-area .testare h3 {
    text-align: left;
    margin-left: 20px;
    font-size: 24px;
  }

  .testare img {
    max-width: none;
  }

  .testare figure {
    margin-bottom: 0;
  }
}

</style>