<template>
  <div id="mm-0" class="mm-page mm-slideout despre_noi despre-noi">
    <page-header :item="header_image" />
    <div class="main-area">
      <div class="first-section pages">
        <intro />
        <beginnings />
        <motivation />
        <inovation />
        <test-section />
        <partners />
        <pre-investition />
        <investition />
        <second-testing />
        <locations />
        <end-section />
      </div>
    </div>
  </div>
</template>

<script>
import Intro from "@/components/about_us/Intro.vue";
import Beginnings from "@/components/about_us/Beginnings.vue";
import Motivation from "@/components/about_us/Motivation.vue";
import Inovation from "@/components/about_us/Inovation.vue";
import TestSection from "@/components/about_us/TestSection.vue";
import Partners from "@/components/about_us/Partners.vue";
import PreInvestition from "@/components/about_us/PreInvestition.vue";
import Investition from "@/components/about_us/Investition.vue";
import SecondTesting from "@/components/about_us/SecondTesting.vue";
import Locations from "@/components/about_us/Locations.vue";
import EndSection from "@/components/about_us/EndSection.vue";
import PageHeader from "@/components/page_header/PageHeader.vue";

export default {
  name: "AboutUs",
  components: {
    Intro,
    Beginnings,
    Motivation,
    Inovation,
    "test-section": TestSection,
    Partners,
    "pre-investition": PreInvestition,
    Investition,
    "second-testing": SecondTesting,
    Locations,
    "end-section": EndSection,
    "page-header": PageHeader,
  },
  computed: {
    header_image() {
      return {
        path: this.$store.getters["seo/seo_image"],
      };
    },
  },

  mounted() {
    this.$store.commit("seo/setParams", {
      seo_title: "Magazin online focare, seminee, cosuri de fum - Despre noi",
      seo_description:
        "Magazinul Pefoc.ro va pune la dispozitie o gama variata de seminee pe lemne, termoseminee, focare, la preturi avantajoase. Noi facem tot, tu pui pe foc!",
      seo_keywords: "focare, seminee pe lemne, termoseminee, perfoc.ro, cosuri de fum",
      seo_image: require("@/assets/images/despre_noi_banner.webp"),
    });
  },
};
</script>

<style scoped>
@import "../../assets/css/about-us.css";
</style>
