<template>
  <section id="inovatie">
    <div class="container-fluid position-relative">
      <div class="inovatie-content container-md">
        <div class="row vertical-align">
          <div
            class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pl-0 pr-0 order2"
          >
            <figure class="figure">
              <img src="@/assets/images/about/cehia.jpg" alt="Inovatie" />
            </figure>
          </div>
          <div
            class="col-xl-5 col-lg-5 col-md-6 col-12 block-text over-bg text-on-left"
          >
            <h3>Specializati si preocupati de inovatie.</h3>
            <p>
              In toti acesti 20 ani am acumulat o experienta vasta, atat in
              cunostinte cat si in aplicarea lor in casele oamenilor.
            </p>

            <p>
              Pentru dezvoltarea departamentului de consultanta si a intregii
              echipe PEFOC, am realizat numeroase specializari in domeniu toate
              acestea cu scopul de a putea oferi clientilor nostri servicii cat
              mai calitative.
            </p>
          </div>
        </div>
      </div>
      <div class="bg-black half-section left"></div>
    </div>
    <div class="container-fluid position-relative">
      <div class="inovatie-content container-md">
        <div class="row vertical-align top">
          <div
            class="col-xl-5 col-lg-5 col-md-6 block-text text-on-right over-bg"
          >
            <h3>
              Specializarile noastre in tari precum: Germania, Cehia si Polonia.
            </h3>
            <p>
              De ce am ales aceste tari?<br />
              Pentru ca pentru noi, calitatea este cea mai importanta valoare pe
              care vrem sa o oferim. in toate aceste tari, am intalnit adevarati
              profesionisti si mesteri in domeniul semineelor, ceea ce ne
              inspira.
            </p>
          </div>
          <div
            class="offset-xl-1 offset-lg-1 offset-md-0 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 right pl-0 pr-0 z-index"
          >
            <figure class="figure">
              <img src="@/assets/images/about/specializare.jpg" alt="Inovatie" />
              <figcaption>
                <p class="img-descrip text-right no-mobile">
                  Echipa PEFOC participand la scolarizari si instruiri in Cehia,
                  Polonia, Germania.
                </p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div class="bg-red half-section right"></div>
    </div>
  </section>
</template>

<style scoped>

@media (max-width: 768px) {

  #inovatie img {
    max-width: 75%;
  }

  #inovatie .no-mobile {
    display: none;
  }

}

@media (max-width: 576px) {

  #inovatie img {
    max-width: 100%;
  }

}


</style>