<template>
  <section id="investim">
    <div class="container-fluid position-relative">
      <div class="inovatie-content first container-md">
        <div class="row vertical-align">
          <div class="col-xl-6 col-lg-6 col-md-6 col-12 pl-0 pr-0 order2">
            <figure class="figure">
              <img src="@/assets/images/about/investitie1.jpg" alt="Inovatie" />
            </figure>
          </div>
          <div class="col-xl-5 col-lg-5 col-md-6 col-12 block-text over-bg text-on-left">
            <h3>Cu focul nu e de joaca!</h3>
            <p>
              Constructia semineelor si a cosurilor de fum implica o responsabilitate
              foarte mare deoarece siguranta si sanatatea multor familii e in joc. De
              aceea este foarte important ca acestea sa fie montate corespunzator, in
              parametri stabiliti de lege la nivel national si european.
            </p>

            <p>
              Montajul corespunzator al unui semineu implica mai multi parametri tehnici
              care trebuie respectati cu strictete, de aceea este necesara o echipa
              specializata.
            </p>
          </div>
        </div>
      </div>
      <div class="bg-red six-section half-section left"></div>
    </div>
    <div class="container-fluid position-relative">
      <div class="inovatie-content four container-md">
        <div class="row vertical-align top">
          <div
            class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 block-text text-on-right over-bg"
          >
            <h3>Precizia montajului perfect.</h3>
            <p>
              Serviciul de montaj seminee, sobe sau cosuri de fum este disponibil in toate
              judetele tarii avand peste 100 de echipe specializate proprii sau partenere
              in Romania instruite de-a lungul anilor in practicarea acestei meserii.<br />
              Constructia semineului se realizeaza conform preferintelor clientilor,
              respectand normele de siguranta si rezistenta, dupa discutii in detaliu si
              consultanta in alegerea celor mai eficiente si potrivite componente, in
              functie de tipul de locuinta.
            </p>
          </div>
          <div
            class="offset-xl-1 offset-md-1 offset-sm-0 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 right pl30 pr-0 z-index"
          >
            <figure class="figure">
              <img src="@/assets/images/about/investitie.webp" alt="Inovatie" />
              <figcaption>
                <p class="img-descrip text-right no-mobile">
                  Montajul semineelor pe lemne se face cu ajutorul materialelor
                  profesionale pentru a fi siguri de rezistenta acestora pe termen lung.
                </p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div class="bg-black six-section half-section right"></div>
    </div>
  </section>
</template>

<style scoped>
@media (max-width: 768px) {
  #investim .no-mobile {
    display: none;
  }
}
</style>
